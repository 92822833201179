import { render, staticRenderFns } from "./DIYReport.vue?vue&type=template&id=0791d74c&scoped=true&"
import script from "./DIYReport.vue?vue&type=script&lang=js&"
export * from "./DIYReport.vue?vue&type=script&lang=js&"
import style0 from "../css/styles.css?vue&type=style&index=0&id=0791d74c&scoped=true&lang=css&"
import style1 from "./DIYReport.vue?vue&type=style&index=1&id=0791d74c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0791d74c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
